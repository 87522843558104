<template>
  <Overview 
        :moduleName="$tc(navName, 1)"
        :moduleNamePlural="$tc(navName, 2)"
        sort="name"
        :newButtonName="$t('form.add', [$t(navName)])"
        :selectFields=selectFields
        :itemService=itemService
        :itemFormRef=itemFormRef
        ref="overview"
    >
        <template #columns>
            <Column field="name" :header="$t('overview.name')" :sortable="true" style="width: 200px"></Column>
            <Column field="email" :header="$t('form.email')" :sortable="true"></Column>
            <Column field="debtor_number" :header="$t('location.debtornr')" :sortable="true"></Column>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import dealerService from '@/services/DealerService'
import store from '@/store/user'

export default {
    components: {
        Overview,
        Column
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$refs.overview.getIndex(from);
        })
    },
    data() {
        return {
            item: {},
            itemService: dealerService,
            itemFormRef: null,
            navName: 'navigation.dealers',
            companyTypes: [],
            selectFields: []
        }
    },
    mounted() {
        //Not customers allowed
        if(store.getters.getUserType == 2){
            this.$router.push({name: 'Home'});
        }
        this.itemFormRef = this.$refs.ItemForm;
    },
    methods: {

    }
    
}
</script>